import React from "react";
import Hero from "./layout/Hero";
import Select from "./layout/Select";

export const Home = () => {
  return <div className="min-h-screen text-black ">
    <Hero/>
    <Select/>
  </div>;
};
