import React from "react";

function LoadingCard({ msg }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white flex flex-col gap-4 items-center p-5 rounded-md">
        <div class="contx">
          <div class="dot"></div>
          <div class="traveler"></div>
        </div>
        <p className="text-black font-bold text-center">{msg}</p>
      </div>
    </div>
  );
}

export default LoadingCard;
