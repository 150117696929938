import React, { useEffect, useState } from "react";
import CustomerForm from "../Order/CustomerForm";
import { AiFillPhone } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LoadingCard from "../cards/LoadingCard";

const caseTypeData = [
  {
    id: 1,
    type: "Normal 3D Slim Cover",
    price: 0,
  },
  {
    id: 2,
    type: "2D Cover",
    price: +200,
  },
  {
    id: 3,
    type: "2 in 1 Premium Cover",
    price: +600,
  },
];

const qrData = [
  {
    id: 1,
    name: "Esewa",
    image: "https://casemandu.com.np/images/payments/esewa-qr.png",
  },
  {
    id: 2,
    name: "Khalti",
    image: "https://casemandu.com.np/images/payments/khalti-qr.png",
  },
  {
    id: 3,
    name: "Bank",
    image: "https://casemandu.com.np/images/payments/gbime-qr.png",
  },
];

function Order() {
  const [paymentMethod, setPaymentMethod] = useState("Esewa");
  const [pReciept, setPReciept] = useState("");
  const [caseType, setCaseType] = useState("Normal 3D Slim Cover");
  const [promoCode, setPromoCode] = useState("");
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [caseTypes, setCaseTypes] = useState([]);
  const [promodata, setPromoData] = useState({});
  const [promoDiscount, setPromoDiscount] = useState(0);
  const url = process.env.REACT_APP_API_URL + "/api";

  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("imageData"))
  );

  useEffect(() => {
    console.log(data);
  }, [data]);

  const navigate = useNavigate();

  const api = "e8b696c3aab89c36b9ebdde1c5fc668a";

  const [loading, setLoading] = useState(false);

  function fetchTypes() {
    fetch(`${url}/caseTypes`)
      .then((response) => response.json())
      .then((res) => {
        setCaseTypes(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function placeOrder() {
    if (!pReciept) {
      alert("Please upload the payment reciept");
      return;
    }
    if (!userDetail) {
      alert("Please add customer details");
      return;
    }
    setLoading(true);
    let message = "";
    if (data?.type === "mobile") {
      message = `CustomCase - Case Type: ${caseType}`;
    } else {
      message = `CustomCase - Case Type: ${data?.type}`;
    }

    const formData = new FormData();
    formData.append("image", pReciept[0]);
    const finalPrice =
      price.finalPrice +
      caseTypeData.find((item) => item.type === caseType).price;

    const priceSummary = {
      promoCode: selectedPromo?.code || "",
      total: finalPrice,
      deliveryCharge: 150,
    };

    console.log(priceSummary);

    fetch("https://api.imgbb.com/1/upload?key=" + api, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error in uploading image");
        }
        return response.json();
      })
      .then((res) => {
        const dataToUpload = {
          name: userDetail?.name,
          orderItems: [
            {
              name: data?.product?.name || data?.product?.model?.title,
              qty: 1,
              image: data?.originalImage,
              variant: caseType,
              price: finalPrice,
            },
          ],
          shippingAddress: userDetail?.shippingAddress,
          province: userDetail?.province,
          city: userDetail?.city,
          phone: userDetail?.phone,
          paymentMethod,
          paymentImage: res.data?.display_url,
          customImage: data?.originalImage,
          priceSummary,
          customCaseCoordinates: data?.croppedAreaPixels,
          additionalInfo: `${message}`,
        };
        fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToUpload),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error in placing order");
            }
            return response.json();
          })
          .then((res) => {
            localStorage.removeItem("imageData");
            let orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
            if (!orderDetails) {
              orderDetails = [];
            }
            orderDetails.push(res);
            localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
            setLoading(false);
            alert("Order Placed Successfully");
            navigate("/history");
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function fetchPromo() {
    fetch(`${url}/promocodes`)
      .then((response) => response.json())
      .then((res) => {
        setPromoData(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    fetchPromo();
    fetchTypes();
  }, []);

  function verifyPromo() {
    if (!!promoCode) {
      let promo = promodata.find((item) => item.code === promoCode);
      if (promo) {
        setSelectedPromo(promo);
        alert("Promo code verified");
      } else {
        alert("Invalid Promo Code");
      }
    }
  }

  useEffect(() => {
    const finalPrice =
      price.finalPrice +
      price.delivery +
      caseTypeData.find((item) => item.type === caseType).price;
    let singlePromoData = selectedPromo;
    if (!singlePromoData) {
      return setPromoDiscount(0);
    }
    const disPrice = (singlePromoData?.discount / 100) * finalPrice;
    const finalPriceAfterPromo =
      disPrice > singlePromoData?.maxAmount
        ? singlePromoData?.maxAmount
        : disPrice;
    console.log(finalPriceAfterPromo, disPrice, singlePromoData?.maxAmount);
    setPromoDiscount(finalPriceAfterPromo);
  }, [selectedPromo]);

  function getUserDetails() {
    setUserDetail(JSON.parse(localStorage.getItem("userDetails")));
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  function hideModel() {
    document.getElementById("my_modal_1").close();
    getUserDetails();
  }

  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  const [price, setPrice] = useState({});

  useEffect(() => {
    const price = {
      discount: 100,
      finalPrice: data?.product?.price,
      delivery: 150,
      netPrice: data?.product?.price + 100,
    };
    setPrice(price);
  }, [data]);

  if (!data) {
    return (
      <div className="h-screen">
        <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto h-full">
          <div className="flex justify-start item-start space-y-2 flex-col">
            <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
              Your Orders
            </h1>
          </div>
          <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
            <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
              <div className="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
                <p className="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">
                  Your Cart
                </p>
                <div className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                  <h2>
                    Your cart is empty. Please go back and add some products to
                    cart.
                  </h2>
                </div>
                <button
                  className="
                  dark:border-white
                  mt-8
                  dark:hover:bg-gray-900
                  dark:bg-transparent
                  dark:text-white
                  py-5
                  hover:bg-gray-200
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-gray-800
                  border
                  border-gray-800
                  font-medium
                  w-96
                  2xl:w-full
                  text-base
                  leading-4
                  text-gray-800
                  "
                  onClick={() => navigate("/")}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className="flex justify-start item-start space-y-2 flex-col">
          <h1 className="text-3xl dark:text-white lg:text-4xl font-semibold leading-7 lg:leading-9 text-gray-800">
            Your Orders
          </h1>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex flex-col justify-start items-start dark:bg-gray-800 bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
              <p className="text-lg md:text-xl dark:text-white font-semibold leading-6 xl:leading-5 text-gray-800">
                Your Cart
              </p>
              <div className="mt-4 md:mt-6 flex flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full">
                <div className="pb-4 md:pb-8 w-full md:w-40">
                  <img
                    className="w-full max-h-52 object-contain hidden md:block"
                    src={data?.originalImage}
                    alt={data?.product?.title}
                  />
                  <img
                    className="w-full max-h-52 object-contain md:hidden"
                    src={data?.originalImage}
                    alt={data?.product?.model?.name}
                  />
                </div>
                <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full pb-8 space-y-4 md:space-y-0">
                  <div className="w-full flex flex-col justify-start items-start space-y-8">
                    <h3 className="text-xl dark:text-white xl:text-2xl font-semibold leading-6 text-gray-800">
                      {data?.product?.name || data?.product?.model?.title}
                    </h3>
                    <div className="flex justify-start items-center gap-5 flex-row  space-y-2"></div>
                  </div>
                  <div className="flex justify-between space-x-8 items-start w-full">
                    <p className="text-base dark:text-white xl:text-lg leading-6">
                      NPR. {data?.product?.price}{" "}
                      <span className="text-red-300 line-through">
                        NPR. {data?.product?.price + 100}{" "}
                      </span>
                    </p>

                    <p className="text-base dark:text-white xl:text-lg font-semibold leading-6 text-gray-800">
                      NPR. {data?.product?.price}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center flex-col md:flex-row  items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 dark:bg-gray-800 space-y-6">
                <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between w-full">
                    <p className="text-base dark:text-white leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                      NPR. {price.netPrice}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base dark:text-white leading-4 text-gray-800">
                      Discount{" "}
                    </p>
                    <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                      -NPR 100 (7.99%)
                    </p>
                  </div>
                  {data?.type === "mobile" && (
                    <div className="flex justify-between items-center w-full">
                      <p className="text-base dark:text-white leading-4 text-gray-800">
                        {caseType}{" "}
                      </p>
                      <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                        {
                          caseTypeData.find((item) => item.type === caseType)
                            .price
                        }
                      </p>
                    </div>
                  )}
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base dark:text-white leading-4 text-gray-800">
                      Shipping
                    </p>
                    <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                      {price.delivery}
                    </p>
                  </div>
                  {!!selectedPromo && (
                    <div className="flex justify-between items-center w-full">
                      <p className="text-base dark:text-white leading-4 text-gray-800">
                        PromoCode ({selectedPromo.code}) (Discount{" "}
                        {selectedPromo.discount}%)
                      </p>
                      <p className="text-base dark:text-gray-300 leading-4 text-gray-600">
                        -{promoDiscount}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base dark:text-white font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base dark:text-gray-300 font-semibold leading-4 text-gray-600">
                    NPR.{" "}
                    {(!!promoDiscount &&
                      price.finalPrice +
                        price.delivery +
                        caseTypeData.find((item) => item.type === caseType)
                          .price -
                        promoDiscount) ||
                      price.finalPrice +
                        price.delivery +
                        caseTypeData.find((item) => item.type === caseType)
                          .price}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <dialog id="my_modal_1" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Hello!</h3>
              <CustomerForm hideModel={hideModel} userDetail={userDetail} />
              <div className="modal-action">
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn">Close</button>
                </form>
              </div>
            </div>
          </dialog>
          <div className="bg-gray-50 dark:bg-gray-800 w-full xl:w-96 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col">
            <h3 className="text-xl dark:text-white font-semibold leading-5 text-gray-800">
              Order details
            </h3>
            {(!!userDetail && (
              <div className="flex flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0">
                <div className="flex flex-col justify-start items-start flex-shrink-0">
                  <div className="flex justify-center w-full md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                    <img
                      src="https://cdn2.iconfinder.com/data/icons/social-media-flat-line/70/user-1024.png"
                      alt="avatar"
                      className="h-12 w-12 object-cover"
                    />
                    <div className="flex justify-start items-start flex-col space-y-2">
                      <p className="text-base dark:text-white font-semibold leading-4 text-left text-gray-800">
                        {userDetail?.name}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-center text-gray-800 dark:text-white md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                    <AiFillPhone />
                    <p className="cursor-pointer text-sm leading-5 ">
                      {userDetail?.phone}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between xl:h-full items-stretch w-full flex-col mt-6 md:mt-0">
                  <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-12 md:space-y-0 md:flex-row items-center md:items-start">
                    <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4 xl:mt-8">
                      <p className="text-base dark:text-white font-semibold leading-4 text-center md:text-left text-gray-800">
                        Shipping Address
                      </p>
                      <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                        {userDetail?.shippingAddress}
                      </p>
                    </div>
                    <div className="flex justify-center md:justify-start items-center md:items-start flex-col space-y-4">
                      <p className="text-base dark:text-white font-semibold leading-4 text-center md:text-left text-gray-800">
                        Address
                      </p>
                      <p className="w-48 lg:w-full dark:text-gray-300 xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                        {userDetail?.city},{userDetail?.province}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full pt-5 justify-center items-center md:justify-start md:items-start">
                    <button
                      onClick={() =>
                        document.getElementById("my_modal_1").showModal()
                      }
                      className="mt-6 md:mt-0 dark:border-white dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base  leading-4 text-gray-800"
                    >
                      Edit Details
                    </button>
                  </div>
                </div>
              </div>
            )) || (
              <div className="flex justify-center items-center w-full">
                <button
                  onClick={() =>
                    document.getElementById("my_modal_1").showModal()
                  }
                  className="dark:border-white mt-8 dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base  leading-4 text-gray-800"
                >
                  Add Customer Detail
                </button>
              </div>
            )}
            <div className="flex mt-4 border-t-2 border-[#00000054] w-full flex-col">
              {data?.type == "mobile" && (
                <div className="flex mt-6">
                  <div className="flex flex-col w-full  pt-5 gap-3">
                    <label htmlFor="caseType">
                      <h3 className="text-lg dark:text-white font-semibold leading-5 text-gray-800">
                        Case Type
                      </h3>
                    </label>
                    <select
                      name="caseType"
                      id="caseType"
                      onChange={(e) => setCaseType(e.target.value)}
                      className="border border-gray-300 bg-transparent text-black rounded-md px-3 py-2"
                    >
                      {caseTypes.map(
                        (item) =>
                          data?.product?.caseTypes.includes(item._id) && (
                            <option key={item._id} value={item.name}>
                              {item.name}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col  pt-5 gap-3">
                  <label htmlFor="pmethod">
                    <h3 className="text-lg dark:text-white font-semibold leading-5 text-gray-800">
                      Payment Method
                    </h3>
                  </label>
                  <select
                    name="pmethod"
                    id="pmethod"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    className="border border-gray-300 bg-transparent text-black rounded-md px-3 py-2"
                  >
                    <option value="Esewa">Esewa</option>
                    <option value="Khalti">Khalti</option>
                    <option value="Bank">Bank</option>
                  </select>
                </div>
                <div className="flex">
                  <img
                    src={
                      qrData.find((item) => item.name === paymentMethod)?.image
                    }
                    alt="qr code"
                    className="w-full object-contain"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col  pt-5 gap-3">
                  <label htmlFor="pproof">
                    <h3 className="text-lg dark:text-white font-semibold leading-5 text-gray-800">
                      Promo Code (if Any)
                    </h3>
                  </label>
                  <div className="flex gap-2 items-center">
                    <input
                      onChange={(e) => setPromoCode(e.target.value)}
                      type="text"
                      name="promo"
                      id="promo"
                      className="border border-gray-300 bg-white text-black rounded-md px-3 py-2"
                    />
                    <button
                      onClick={verifyPromo}
                      className="
                     bg-black rounded-md text-white p-2 
                      "
                    >
                      Check
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col  pt-5 gap-3">
                  <label htmlFor="pproof">
                    <h3 className="text-lg dark:text-white font-semibold leading-5 text-gray-800">
                      Payment Reciept
                    </h3>
                  </label>
                  <input
                    type="file"
                    name="pproof"
                    id="pproof"
                    onChange={(e) => setPReciept(e.target.files)}
                    className="border border-gray-300 bg-transparent text-black rounded-md px-3 py-2"
                  />
                </div>

                <div className="flex">
                  {pReciept && (
                    <img
                      src={URL.createObjectURL(pReciept[0])}
                      alt="qr code"
                      className="w-full object-contain"
                    />
                  )}
                </div>
              </div>
            </div>
            {(!loading && (
              <button
                onClick={placeOrder}
                className="dark:border-white mt-8 dark:hover:bg-gray-900 dark:bg-transparent dark:text-white py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base  leading-4 text-gray-800"
              >
                Place Order
              </button>
            )) || <LoadingCard msg="Placing Order" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
