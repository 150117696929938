import React, { useEffect, useState, useCallback, useRef } from "react";
import { Tooltip } from "@material-tailwind/react";
import { IoAddCircle, IoArrowBack, IoMove, IoPencil } from "react-icons/io5";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../cropImage";
import * as htmlToImage from "html-to-image";

export default function Editor({ image, imageUrl, data, orderNow }) {
  const [height, setHeight] = useState(550); // Set initial height
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const [ratio, setRatio] = useState(8 / 16);
  const imgref = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!!image) {
      setMessage("Double click to edit the Image");
      setShowModal(true);
    } else {
      setMessage("Add Image from Image Panel");
    }
  }, [image]);

  useEffect(() => {
    if (!!image) {
      const imgWidth = imgref.current.width;
      const imgHeight = imgref.current.height;

      setImageSize({
        width: imgWidth * (1 - 2 * 0.192), // Width reduced by 20.53% from both sides
        height: imgHeight * (1 - 0.1211), // Height reduced by 20.74% from top and 17.42% from bottom
      });
    }
  }, [image]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      const imageSend = await croppedImage.imageSend;
      setCroppedImage(imageSend);
      setShowModal(false);
      const imagex = document.getElementById("imagePreview");
      // convert the div to image
      htmlToImage.toBlob(imagex).then(function (dataUrl) {
        const urlImage = URL.createObjectURL(dataUrl);
        data({
          ...data,
          imageSend: imageSend,
          croppedAreaPixels,
          previewImage: urlImage,
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  async function getImagefromDiv() {
    const image = document.getElementById("imagePreview");
    // convert the div to image
    htmlToImage.toBlob(image).then(function (dataUrl) {
      const urlImage = URL.createObjectURL(dataUrl);
      setPreviewImage(urlImage);
    });
  }

  return (
    <div className="text-3xl md:w-2/3 w-full flex-col  p-3 min-h-screen flex justify-around items-center">
      <div
        className="
          rounded-md
          p-4
          gap-10
          flex
          justify-center
          items-center
          flex-col
          relative
          overflow-hidden"

        // Add overflow-hidden
      >
        {image && (
          <button
            className={`
              ${showModal ? "bg-blue-500" : "bg-gray-500"}
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              text-sm
            `}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <IoPencil />
          </button>
        )}

        {/* Image preview */}
        <div
          className="flex
            w-full
        bg-white
        relative
      "
          style={{
            height: `550px`,
            overflow: "hidden",
          }}
          id="imagePreview"
        >
          {/* background */}

          <img
            onDoubleClick={() => {
              if (image) {
                setShowModal(true);
              } else {
                setShowModal(false);
              }
            }}
            src={imageUrl}
            alt=""
            ref={imgref}
            className="
              scale-[1.4]
             z-20 "
            style={{
              objectFit: "contain",
            }}
          />
          <div
            className="bg-red-200  absolute  z-10 "
            style={{
              top: `${imageSize?.height * 0.1}px`,
              left: `${imageSize?.width * 0.34}px`,
              bottom: `${imageSize?.height * 0.1742}px`,
              height: `${imageSize?.height}px`,
              width: `${imageSize?.width}px`,
              backgroundImage: `url(${croppedImage ? croppedImage : image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>

          {/* image */}
          {/* {image && (
            <img
              drag
              src={croppedImage ? croppedImage : image}
              alt=""
              className="md:left-[40px] left-[45px]"
              style={{
                height: `${height}px`,
                objectFit: "contain",
                position: "absolute",
                scale: "0.92",
                marginTop: "10px",
                top: `${crop.y}px`,
              }}
            />
          )} */}
        </div>
      </div>

      {image && (
        <button
          className={`
              hover:bg-green-700
              text-white
              py-2
              px-4
              z-50
              rounded
              duration-300
              text-sm
              bg-green-500
              fixed
              bottom-10
              left-5

            `}
          onClick={() => {
            orderNow();
          }}
        >
          Order Now
        </button>
      )}
      {/* Model */}

      {showModal && (
        <div
          className="fixed 
          h-full
          w-full
          flex
          justify-center
          items-center
          flex-col
          z-50
          top-0
          left-0
        "
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            backdropFilter: "blur(5px)",
          }}
        >
          <div className="flex flex-col gap-5 p-5">
            {/* Crop Area */}

            <div
              className="flex"
              style={{
                height: "80vh",
                width: "80vw",
                position: "relative",
              }}
            >
              <Cropper
                image={image}
                crop={crop}
                height={550}
                zoom={zoom}
                aspect={ratio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            {/* slider for zoom */}
            <input
              type="range"
              min="1"
              max="3"
              step="0.001"
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />

            <div className="flex gap-5">
              <button
                className={`
              hover:bg-blue-700
              text-white
              py-2
              px-4
              rounded
              text-sm
              bg-blue-500
            `}
                onClick={() => {
                  getImagefromDiv();
                  showCroppedImage();
                }}
              >
                Save
              </button>
              <button
                className={`
              hover:bg-blue-700
              text-white
              py-2
              px-4
              rounded
              text-sm
              bg-gray-500
            `}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
