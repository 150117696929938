import { useState, useEffect, useRef } from 'react'; 
import SingleSupport from "./SingleSupport";
import { IoIosArrowBack } from "react-icons/io";

function SupportCard({ showModel }) {
    const [option, setOption] = useState();
    const options = [
        "Request for Model",
        "Problem with Ordering",
        "Problem with Customization",
        "Feedback and Support",
        "Others",
      ];

  return (
    <div
      className="md:w-2/5 relative w-full max-h-[80vh] overflow-hidden overflow-y-scroll  noScroll bg-white text-black flex flex-col slideUp  rounded-md shadow-xl p-5 border border-gray-600 ">
      <h2 className="text-xl text-center w-full pb-4 border-b border-b-blue-gray-200 font-bold text-black">
        Support and Feedback
      </h2>
      <div className="absolute top-5 right-4 ">
        <button
          className="bg-blue-500 hover:bg-blue-400 duration-300 text-white px-2 py-1 rounded-full h-8 w-8"
          onClick={() => showModel(false)}
        >
          X
        </button>
      </div>
      {!!option &&  <div className="absolute top-5 left-4 ">
        <button
          className="bg-gray-500 hover:bg-gray-400 duration-300 text-white px-2 py-1 rounded-full h-8 w-8"
          onClick={() => setOption(null)}
        >
          <IoIosArrowBack />
        </button>
      </div>}
     {(!option) && <div className="flex flex-col gap-3">
        <div className="flex gap-3 flex-wrap pt-4">
          <div onClick={(e)=>{
            setOption(1)
          }} className="flex bg-gray-300 cursor-pointer duration-300 hover:bg-gray-400 rounded-full p-3">
            Request for Model
          </div>
          <div onClick={(e)=>{
            setOption(2)
          }} className="flex bg-gray-300 cursor-pointer duration-300 hover:bg-gray-400 rounded-full p-3">
            Problem with Ordering
          </div>
          <div onClick={(e)=>{
            setOption(3)
          }} className="flex bg-gray-300 cursor-pointer duration-300 hover:bg-gray-400 rounded-full p-3">
            Problem with Customization
          </div>
          <div onClick={(e)=>{
            setOption(4)
          }} className="flex bg-gray-300 cursor-pointer duration-300 hover:bg-gray-400 rounded-full p-3">
            Feedback and Support
          </div>
          <div onClick={(e)=>{
            setOption(5)
          }} className="flex bg-gray-300 cursor-pointer duration-300 hover:bg-gray-400 rounded-full p-3">Others</div>
        </div>
      </div>
      ||
      <SingleSupport option={option} setOption={setOption} options={options[option-1]} />
      }

    </div>
  );
}

export default SupportCard;
