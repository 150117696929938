import React, { useEffect } from 'react'
import Editor from './Editor'
import EditorMenu from './EditorMenu'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import LoadingCard from '../../cards/LoadingCard'

// Default values shown

function MobileHomepage() {
  const navigate = useNavigate()

  const [image, setImage] = React.useState(null)
  const [imageUrl, setImageUrl] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [product, setProduct] = React.useState(null)
  const [ratio, setRatio] = React.useState(null)
  const [data, setData] = React.useState({})
  const { id } = useParams()
  function handleImageChange(e) {
    setImage(e)
  }

  useEffect(() => {
    const fetchSingleModel = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/phones/models/${id}`
      )
      const data = await response.json()

      if (data) {
        setImageUrl(data.templateImg)
        const newRatio = data.ratio.width / data.ratio.height
        setRatio(newRatio)
        setProduct(data)
      }
    }
    fetchSingleModel()
  }, [id])

  function orderNow() {
    if (!data.imageSend) {
      alert('Please select the image first')
      return
    }
    setLoading(true)

    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        // Read the blob as data URL
        const reader = new FileReader()
        reader.onloadend = () => {
          // Get the base64 encoded string
          const base64String = reader.result.split(',')[1]
          uploadToImgbb(base64String)
        }
        reader.readAsDataURL(blob)
      })
      .catch((error) => console.error('Error fetching the image:', error))
  }
  const api = 'e8b696c3aab89c36b9ebdde1c5fc668a'

  function uploadToImgbb(e) {
    const formData = new FormData()
    formData.append('image', e)

    fetch('https://api.imgbb.com/1/upload?key=' + api, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        const imageData = {
          originalImage: res.data?.display_url,
          productId: product._id,
          croppedAreaPixels: data?.croppedAreaPixels,
          product: product,
          type: 'mobile',
        }
        localStorage.setItem('imageData', JSON.stringify(imageData))
        setLoading(false)
        navigate('/order')
      })

      .catch((error) => {
        console.error('Error:', error)
        alert('Error: ' + error)
        setLoading(false)
      })
  }

  if (!id) {
    navigate('/404')
  }

  if (!product) return <div>Loading...</div>

  return (
    <div className='flex md:flex-row flex-col w-full p-5'>
      {loading && <LoadingCard msg='Making Order' />}
      <Editor
        orderNow={orderNow}
        data={setData}
        imageUrl={imageUrl}
        ratio={ratio}
        image={image}
      />
      <EditorMenu
        orderNow={orderNow}
        product={product}
        image={handleImageChange}
      />
    </div>
  )
}

export default MobileHomepage
