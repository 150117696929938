import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.css'
import MobileHomepage from './Components/Editor/mobileCases/Homepage'
import HistoryMain from './Components/History/HistoryMain'
import { Home } from './Components/Home'
import Navbar from './Components/layout/Navbar'
import NotFound from './Components/pages/404'
import Order from './Components/pages/Order'
import Phones from './Components/pages/Phones'
import SingleBrand from './Components/pages/SingleBrand'
import Homepage from './Components/Editor/Homepage'

function App() {
  return (
    <div className='App bg-white'>
      {/* add routes */}
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/cases' element={<Phones />} />
          <Route path='/cases/:id' element={<SingleBrand />} />
          <Route path='/customize/:id' element={<Homepage />} />
          <Route path='/customize/cases/:id' element={<MobileHomepage />} />
          <Route path='/order' element={<Order />} />
          <Route path='/history' element={<HistoryMain />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
