import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { useParams } from "react-router-dom";
import SinglePhone from "../cards/SinglePhone";
import SupportCard from "../cards/SupportCard";
import { IoIosHelpCircleOutline } from "react-icons/io";
import LoadingCard from "../cards/LoadingCard";

function SingleBrand() {
  const { id } = useParams();
  const [models, setModels] = React.useState([]);
  const [showModel, setShowModel] = React.useState(false);
  const [primaryModel, setPrimaryModel] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  function getModel(id) {
    const url =
      "https://casemandu-api.casemandu.com.np/api/phones/brands/" + id;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!!data?.models) {
          const model = data?.models;
          const onlyifImage = model.filter(
            (item) =>
              item?.templateImg !== null &&
              item?.templateImg !== "" &&
              item?.templateImg !== undefined &&
              item?.templateImg !== "null" &&
              item?.templateImg !== "undefined" &&
              item?.templateImg !== " "
          );
          setModels(onlyifImage);
          setPrimaryModel(onlyifImage);
        }
      });
  }
  function resetModel() {
    setModels(primaryModel);
  }

  const handleSearch = (search) => {
    const newdata = primaryModel.filter((item) =>
      item?.name.toLowerCase().includes(search.toLowerCase())
    );

    if (newdata.length === 0) return;

    setModels(newdata);
  };

  useEffect(() => {
    getModel(id);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [id]);

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="h-96 w-full flex justify-center items-center">
        <div
          className="flex h-full justify-center items-center md:w-10/12 w-full"
          style={{
            backgroundImage: `url(https://www.printshoppy.com/image/catalog/v5/webp/mobile-cases/landing-mc-models-page-banner-d.webp)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      {loading && <LoadingCard msg={"Loading Models"} />}

      <div className="min-h-96 md:w-10/12 w-full flex flex-col items-start  rounded-md  transition-all ">
        <div className="search flex w-full  p-5 flex-col gap-5">
          <h2 className="text-xl text-center text-black font-bold">
            SEARCH YOUR SMART PHONE MODEL
          </h2>
          <div className="flex border-2 p-3 rounded-md">
            <div className="flex">
              <AiOutlineSearch size={32} />
            </div>
            <input
              onChange={(e) => handleSearch(e.target.value)}
              type="text"
              placeholder="Search your phone model"
              className="w-full bg-transparent border-none focus:outline-none text-black pl-3"
            />
            <div
              onClick={() => setModels(primaryModel)}
              className="flex cursor-pointer"
            >
              <AiFillCloseCircle size={32} />
            </div>
          </div>
        </div>

        <div className="search flex w-full p-2 items-center gap-5 justify-between px-8 bg-purple-600 rounded-md">
          <h2 className="md:text-xl text-md text-white">
            Didn't find the desired model or need any help?
          </h2>
          <div
            className="flex justify-center items-center bg-white border-2 border-[#0000002a] h-10 w-10 rounded-full cursor-pointer hover:bg-[#f7ff613d] hover:text-black text-black"
            onClick={() => {
              setShowModel(true);
            }}
          >
            <IoIosHelpCircleOutline size={32} />
          </div>
        </div>

        {models?.length === 0 ? (
          <div className="flex justify-center items-center h-96 w-full">
            <h2 className="text-2xl font-bold text-black">No Data Found</h2>
          </div>
        ) : (
          <div
            className="
        grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-5 w-full
        "
          >
            {models?.map((model) => (
              <SinglePhone key={model?._id} model={model} />
            ))}
          </div>
        )}
      </div>
      {showModel && (
        <div className="flex fixed h-screen w-full p-2 top-0 left-0 z-50 backdrop-blur-lg bg-[#ffffff2a] justify-center items-center">
          <SupportCard showModel={setShowModel} />
        </div>
      )}
    </div>
  );
}

export default SingleBrand;
